.navbar {
  background-color: #2d4356;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.logo-container {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
}

/* .logo {
    height: 40px;
    margin-right: 10px;
  }
   */
.logo-text {
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar-right {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-size: 1rem;
}

.nav-link:hover {
  text-decoration: underline;
}

.user-profile {
  /* display: flex; */
  /* align-items: center;
    gap: 0.5rem; */
}

.avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar img {
  width: 25px;
  height: 25px;
}

.username {
  /* font-size: 1rem; */
}

/* Active state with no animation */
.nav-item.active {
  border-bottom: 6px solid #db6412;
}

/* Left-to-right border animation */
.left-to-right::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 6px;
  background-color: #db6412;
  animation: slide-left-to-right 0.3s forwards;
}

@keyframes slide-left-to-right {
  0% {
    width: 0;
    left: 0;
  }
  100% {
    width: 100%;
    left: 0;
  }
}

/* Right-to-left border animation */
.right-to-left::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 6px;
  background-color: #db6412;
  animation: slide-right-to-left 0.3s forwards;
}

@keyframes slide-right-to-left {
  0% {
    width: 0;
    right: 0;
  }
  100% {
    width: 100%;
    right: 0;
  }
}

@font-face {
  font-family: "Inter";
  src: url("../Inter/Inter-Italic-VariableFont_opsz,wght.ttf")
    format("truetype");
}

.header {
  background-color: #294859;
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 68px;
  
}

.logo img {
  width: 60px;
  height: 60px;
}

.header-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  padding: 5px;
  border-radius: 10px;
}

.login-link {
  /* color: #F2F2F2; */
  text-transform: uppercase;
  width: 100px;
  padding: 5px;
  border-radius: 10px;
  text-decoration: none;
  margin-bottom: 24px;
}

.login-underline {
  width: 121px;
  height: 5px;
  background-color: #db6412;
}
/* Hero Section */
.hero {
  /* display: grid;
    grid-template-columns: 1fr 1fr; */
  gap: 40px;
  padding: 60px 0;
  background-color: #f4f4f4;
}
.hero-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}
.hero-content h1 {
  /* font-size: 48px; */
  line-height: 1.2;
  margin-bottom: 40px;
}
/* .hero-images {
    padding: 20px;
    min-height: 707px;
  }
   */
.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 130px);
  gap: 16px;
  justify-content: center;
}

.grid-item {
  width: 130px;
  height: 130px;
  background-color: #d1d1d1;
  overflow: hidden;
  position: relative;
}

.image-container {
  position: absolute;
  /* width: 227px;
    height: 151px; */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.grid-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.notify-form {
  display: flex;
  width: fit-content;
  height: 53px;
  position: relative;
  margin-bottom: 20px;
  /* }
  
  .notify-form input {
    /* flex: 1;
    height: 50px;
    background: #FFFFFF;
    border: none;
    padding-left: 25px;
    font-size: 18px;
    color: #333; */
  border: none !important;
}

.notify-form input::placeholder {
  color: #b3b3b3;
}

.notify-form button {
  width: 136px;
  height: 51px;
  background-color: #db6412;
  border: none;
  color: #f1f1f1;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}
 .heading {
  letter-spacing: 2px;
}

.notify-form button:hover {
  background-color: #c55810;
}

.grid-item {
  width: 130px;
  height: 130px;
  background-color: #d1d1d1;
  overflow: hidden;
  position: relative;
}

.grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.grid-item.accent {
  background-color: #db6412;
}
.highlight {
  color: black; /* Orange color from the design */
}

.waitlist-signup {
  margin-top: 40px;
}

.waitlist-signup h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.waitlist-signup form {
  display: flex;
  /* gap: 10px; */
  margin-top: 20px;
}

.waitlist-signup input {
  flex: 1;
  padding: 12px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.waitlist-signup button {
  background-color: #e65c1a;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.waitlist-signup button:hover {
  background-color: #d45315;
}

/* .hero-images {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  } */

/* Features Section */
.features {
  padding: 80px 0;
  text-align: center;
}

.features h2 {
  font-size: 27px;
  margin-bottom: 60px;
}

/* .main-features {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-bottom: 60px;
  } */

.feature-card {
  /* background: linear-gradient(to bottom, #f5f5f5, #e0e0e0); */
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
/* .secondary-feature-card img{
    height: 42px;
    width: 42px;
  } */
.feature-card img {
  width: 100%;
  /* margin-bottom: 20px; */
  /* margin: auto; */
}
.secondary-features {
  /* display: flex;
    justify-content: center;
    flex-wrap: wrap; */
}

.secondary-feature-card {
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.image-center {
  display: block;
  margin: 0 auto; /* Ensures image is centered */
}

.text-center {
  margin-top: 8px; /* Adds space between the image and text */
  text-align: center;
}
/* .secondary-features {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 20px;
    margin-top: 40px;
  } */

/* CTA Section */
.cta {
  text-align: center;
  padding: 80px 0;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.play-button {
  background-color: #e65c1a;
  color: white;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-top: 20px;
}

/* Footer */
footer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  background-color: #294859; /* Dark blue from the design */
  color: white;
  /* margin-top: 80px; */
}

/* footer h3 {
    margin-bottom: 20px;
    font-size: 16px;
  }
   */
footer p,
footer a {
  color: #ffffff;
  text-decoration: none;
  line-height: 1.6;
}

.footer-logo img {
  height: 40px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero {
    grid-template-columns: 1fr;
  }

  .main-features {
    grid-template-columns: 1fr;
  }

  .secondary-features {
    grid-template-columns: repeat(3, 1fr);
  }

  footer {
    grid-template-columns: 1fr;
    text-align: center;
  }
}

/* Typography Base Styles */
:root {
  /* Convert px to rem for better scaling */
  --h1-desktop: 3rem; /* 48px */
  --h1-mobile: 2rem; /* 32px */
  --h2-desktop: 2.25rem; /* 36px */
  --h2-mobile: 1.75rem; /* 28px */
  --body-desktop: 1rem; /* 16px */
  --body-mobile: 0.875rem; /* 14px */
  --cta-desktop: 1.125rem; /* 18px */
  --cta-mobile: 1rem; /* 16px */
  --small-text: 0.75rem; /* 12px */
}

/* Header Styles */
h1 {
  font-size: var(--h1-desktop);
  font-weight: 500; /* Medium */
  line-height: 1.2;
}

h2 {
  font-size: var(--h2-desktop);
  font-weight: 600; /* Semi-Bold */
  line-height: 1.3;
}

/* Body Text */
.primary-text {
  font-size: var(--body-desktop);
  font-weight: 400; /* Regular */
  line-height: 1.6;
}

.secondary-text {
  font-size: 0.875rem; /* 14px */
  font-weight: 300; /* Light */
  line-height: 1.5;
}

/* CTA Button Text */
.waitlist-signup button,
.cta button {
  font-size: var(--cta-desktop);
  font-weight: 400; /* Bold */
  line-height: 1.2;
}

/* Footer Text */
footer {
  font-size: var(--small-text);
  font-weight: 400; /* Regular */
  line-height: 1.4;
}

/* Responsive Typography */
@media (max-width: 768px) {
  h1 {
    font-size: var(--h1-mobile);
  }

  h2 {
    font-size: var(--h2-mobile);
  }

  .primary-text {
    font-size: var(--body-mobile);
  }

  .secondary-text {
    font-size: 0.75rem; /* 12px */
  }

  .waitlist-signup button,
  .cta button {
    font-size: var(--cta-mobile);
  }
}

.form-css .inputbutton {
  font-size: 14px !important;
  border: none !important;
  max-width: 200px !important;
  width: 100% !important;
  outline: none !important;
  background-color: white !important;
  border-radius: 5px;
  padding: 10px 10px !important;
}

.form-css .inputbutton:focus {
  border: none !important;
  outline: none !important;
}

.main-box-container {
  display: flex;
  flex-direction: row;
}

.box-container-one {
  width: 50%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.text-alignment {
  text-align: left;
}

.box-container-two {
  width: 50%;
}

@media only screen and (max-width: 1175px) {
  .box-container-one {
    width: 60%;
  }

  .box-container-two {
    width: 45%;
  }
}

@media only screen and (max-width: 1024px) {
  .main-box-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .box-container-one {
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .text-alignment {
    text-align: center;
  }

  .box-container-two {
    width: 100%;
  }

  .form-css {
    margin-top: 10px;
    padding: 0px 25px;
  }
  .form-css .inputbutton {
    font-size: 14px !important;
    border: none !important;
    max-width: 100% !important;
    width: 100% !important;
    outline: none !important;
    background-color: white !important;
    border-radius: 5px;
    padding: 8px 10px !important;
  }
}

.custom-notification-error .ant-notification-notice-message {
  color: red !important; /* Ensure your styles override default ones */
}

.custom-notification-success .ant-notification-notice-message {
  color: #198754 !important; /* Ensure your styles override default ones */
}

.custom-notification-default .ant-notification-notice-message {
  color: none !important; /* Ensure your styles override default ones */
}

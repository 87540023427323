.dashboard-container {
    width: 100%;
    /* min-height: 100vh; */
    background: #fff;
  }
  
  .cards-outer-container {
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
  }
  
  .cards-scroll-container {
    display: flex;
    gap: 20px;
    overflow-x: auto;
    /* padding: 20px 10px; */
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
    
    /* Hide scrollbar but keep functionality */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
  }
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  .cards-scroll-container::-webkit-scrollbar {
    display: none;
  }
  
  .card {
    min-width: 280px;
    height: 400px;
    background: linear-gradient(180deg, #beafa0 0%, #4f6269 100%);
    border-radius: 20px;
    padding: 30headerpx;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .card h2 {
    color: white;
    font-size: 2rem;
    text-align: center;
    margin-top: 40px;
  }
  
  .continue-btn {
    background: #e67e22;
    color: white;
    border: none;
    padding: 12px 40px;
    border-radius: 25px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.2s ease;
    margin-bottom: 40px;
  }
  
  .continue-btn:hover {
    background: #d35400;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .cards-scroll-container {
      gap: 15px;
      padding: 15px 5px;
    }
    
    .card {
      min-width: 250px;
      height: 350px;
    }
    
    .card h2 {
      font-size: 1.75rem;
    }
  }

  /* Modal Overlay */
.home-selection-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Container */
.modal-content {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
  text-align: center;
  max-width: 600px;
  width: 90%;
  height: 60%;
}

/* Modal Title */
.modal-content h2 {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
}

/* Home Card Container */
.home-list {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

/* Home Card */
.home-card {
  flex: 1;
  max-width: 150px;
  border: 2px solid #e5e5e5;
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.home-card:hover {
  transform: scale(1.05);
  border-color: #db6312; /* Add accent color on hover */
}

/* Selected Card */
.home-card.selected {
  border-color: #db6312;
  background-color: #fdf8f3;
}

/* Home Image */
.home-card img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  margin: auto;
}

/* Home Name */
.home-card p {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

/* Button (Optional) */
.continue-btn {
  background-color: #db6312;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.continue-btn:hover {
  background-color: #b9530e;
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Ensures it covers the entire viewport */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* Optional: Add a translucent background */
  z-index: 9999; /* Keeps it on top of other content */
}

  @media only screen and (max-width: 1100px) {
    /* .mainContainer {
      margin: 80px 20px 0px 20px;
      display: flex;
      flex-direction: column;
    } */
  }  
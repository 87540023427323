.add-task-form {
  /* max-width: 1200px; */
  padding: 6px 8px;
  display: flex;
  flex-direction: row;
  transition: all 0.5s ease-in-out;
}
.ant-radio-inner {
  width: 16px; /* Adjust the size of the radio button */
  height: 16px; /* Adjust the size of the radio button */
  border-width: 2px; /* Set the border thickness */
  border-color: #205368 !important; /* Green border color */
  background-color: white !important; /* Default white background */
}

/* Style for the Radio button when checked */
.ant-radio-checked .ant-radio-inner {
  border-color: #205368 !important; /* Green border when checked */
  background-color: white !important; /* Keep background white */
}

/* Style for the inner circle when checked (the dot) */
.ant-radio-checked .ant-radio-inner::after {
  content: "";
  width: 14px;
  height: 14px;
  background-color: #205368;
  border-radius: 50%;
  top: 7px;
  left: 7px;
}
.ant-upload-wrapper {
  display: flex !important;
}
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select {
  width: 50px !important;
  height: 50px !important;
  background-color: #f7ebda !important;
  border: none;
}
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select
  button {
  color: #db6312;
}

.task-details {
  width: 48%;
}

.task-schedule {
  width: 32%;
}

.additional-details {
  width: 20%;
}

.task-details,
.task-schedule,
.additional-details {
  /* width: 100%; */
  background: white;
  /* border-radius: 8px; */
  padding: 10px;
  /* margin-bottom: 20px; */
}
.task-div form {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.input-group {
  /* max-width: 220px; */
  width: 100%;
  /* margin-bottom: 20px; */
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 6px;
}

.input-group label {
  display: block;
  /* margin-bottom: 8px; */
  font-weight: 500;
}

.assignee-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.time-inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 18px;
}

.photo-upload {
  margin: 20px 0;
}

.checkboxes label {
  display: flex;
  align-items: center;
  /* gap: 5px; */
  /* margin-bottom: 10px; */
}
.image-size-foruploadimages .ant-upload-list-item-container {
  height: 80px !important;
  width: 80px !important;
}

.submit-button {
  background-color: #ff6b35;
  color: white;
  width: 180px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
}

.submit-button:hover {
  background-color: #e85a2c;
}

.char-count {
  font-size: 12px;
  color: #666;
  text-align: right;
}
.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #dfdddd;
}

.task-error {
  order: -1;
  /* background-color: #fff0f0; */
  /* box-shadow: 0 0 10px rgba(255, 0, 0, 0.1); */
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.text-size-handle {
  font-size: 14px;
}

@media (max-width: 1196px) {
  .task-details {
    width: 44%;
  }

  .task-schedule {
    width: 32%;
  }

  .additional-details {
    width: 24%;
  }
}

@media (max-width: 1031px) {
  .task-details {
    width: 44%;
  }

  .task-schedule {
    width: 30%;
  }

  .additional-details {
    width: 25%;
  }
}

@media (max-width: 1000px) {
  .add-task-form {
    flex-direction: column;
  }
  .task-details {
    width: 100%;
  }

  .task-schedule {
    width: 100%;
  }

  .additional-details {
    width: 100%;
  }
}

.task-container {
  display: grid;
  gap: 15px; /* Adjust spacing between grid items */
  grid-template-columns: repeat(5, 1fr); /* Default: 1 column */
}

@media (max-width: 1930px) {
  .task-container {
    grid-template-columns: repeat(4, 1fr); /* Default: 1 column */
  }
}

@media (max-width: 1630px) {
  .task-container {
    grid-template-columns: repeat(3, 1fr); /* Default: 1 column */
  }
}

@media (max-width: 1240px) {
  .task-container {
    grid-template-columns: repeat(2, 1fr); /* Default: 1 column */
  }
}

@media (max-width: 890px) {
    .task-container {
      grid-template-columns: repeat(1, 1fr); /* Default: 1 column */
    }
  }
  
